module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-react-helmet-canonical-urls/gatsby-browser.js'),
      options: {"plugins":[],"siteUrl":"https://angstfabriek.nl"},
    },{
      plugin: require('../node_modules/gatsby-plugin-catch-links/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-nprogress/gatsby-browser.js'),
      options: {"plugins":[],"color":"#24A42E","showSpinner":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-typography/gatsby-browser.js'),
      options: {"plugins":[],"pathToConfigModule":"/opt/build/repo/node_modules/@wingscms/hummingbird/lib/typography.js"},
    },{
      plugin: require('../node_modules/@wingscms/hummingbird/gatsby-browser.js'),
      options: {"plugins":[],"wings":{"project":"0NAXNkYrxYTKE6yVGKn1","appKey":"b44b19928c82b5704af660dd58cb8a7462df5c9a56ffeab6"},"blockRobots":false,"design":{"siteTitle":"De Angstfabriek","primaryColor":"#ffed00","secondaryColor":"#000","logoUrl":"https://files.wings-platform.com/0NAXNkYrxYTKE6yVGKn1/1551111551484/af-logo2x.png","faviconImageUrl":"https://files.wings-platform.com/0NAXNkYrxYTKE6yVGKn1/1551111551484/af-logo2x.png","intentPrimary":"#edff00","appBackgroundColor":"#000","darkAppBackgroundColor":"#212121","textColor":"#212121","headingColor":"#000","linkColor":"#edff00","darkTextColor":"#000","darkHeadingColor":"#000","darkLinkColor":"#edff00","iconColor":"#edff00","darkIconColor":"#edff00","footerContent":[[["title","Volg de Angstfabriek:"],["social",[["instagram","https://www.instagram.com/angstfabriek","#000"],["twitter","https://www.twitter.com/MvanDoem","#000"]]]],[["title","Volg Critical Mass:"],["social",[["facebook","https://www.facebook.com/StichtingCriticalMass/","#000"],["twitter","https://twitter.com/CriticalMassNu","#000"]]]],[["title","Blijf op de hoogte:"],["mailchimp","https://angstfabriek.us20.list-manage.com/subscribe/post?u=08eab46c0527e288abcb4edd5&amp;id=2102b4bc92","ABONNEER","Dank voor je aanmelding! Check je inbox voor een bevestigingsmail."]]]},"typography":{"scaleRatio":1.5,"headerWeight":"bold","headerFontFamily":["Edmondsans","sans-serif"],"bodyFontFamily":["Roboto","sans-serif"],"googleFonts":[{"name":"Roboto","styles":["400","400i","700","700i"]}]},"footer":{"title":"Wings","logoLink":"https://wings.dev","columns":[{"title":"Contact","rows":[{"type":"text","content":"Voorhaven 31<br/>3025HC Rotterdam<br/>Netherlands"},{"type":"link","url":"mailto:info@wings.dev","content":"info@wings.dev"}]},{"title":"Social","rows":[{"type":"social","profiles":[{"platform":"twitter","url":"https://twitter.com/wingscms"}]}]}]}},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-68938553-2"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
