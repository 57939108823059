import React from 'react';
import { createGlobalStyle } from 'styled-components';
import Edmondsans from './src/fonts/Edmondsans_Bold.ttf';

const Fonts = createGlobalStyle`
  * {
    -webkit-font-smoothing: antialiased;
  }
  @font-face {
    font-family: "Edmondsans";
    font-style: bold;
    src: url(${Edmondsans});
  }
`;

export const wrapRootElement = ({ element }) => {
  return (
    <React.Fragment>
      <Fonts />
      {element}
    </React.Fragment>
  );
};
